import { useEffect, useState } from 'react'
import { AddIcon } from '@chakra-ui/icons'
import {
  TabPanel,
  Text,
  Button,
  HStack,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  TableContainer,
  Box,
  useToast,
  Checkbox,
  CheckboxGroup,
  Stack, VStack
} from '@chakra-ui/react'
import { Card, Input } from 'components'
import { CiImport, CiExport } from 'react-icons/ci'
import AddCategory from '../modals/AddCategory'
import { useAuth, useDatas } from 'contexts'
import AddTask from '../modals/AddTask'
import EditTask from '../modals/EditTask'
import ConfirmDelete from '../../../../../contexts/modals/components/confirmDelete'
import HistoricalModal from '../modals/HistoricalModal'
import ImportTask from '../modals/ImportTask'
import EditChapter from '../modals/EditChapter'
import AddTitle from '../modals/AddTitle'
import InterventionTitleLine from '../InterventionTitleLine'
import InterventionTaskLine from '../InterventionTaskLine'
import EditTitle from '../modals/EditTitle'

const Interventions = ({ chantier }) => {
  const { callApi } = useAuth()
  const { datas: { measures } } = useDatas()

  const toast = useToast()
  /* Ouverture des modales d'edition/suppression/création de tâches/categories */
  const [modalOpen, setModalOpen] = useState(false)
  const [modalOpenCategory, setModalOpenCategory] = useState(false)
  const [modalOpenTitle, setModalOpenTitle] = useState(false)
  const [modalEditChapterOpen, setModalEditChapterOpen] = useState(false)
  const [modalEditTitleOpen, setModalEditTitleOpen] = useState(false)
  const [modalEditOpen, setModalEditOpen] = useState(false)
  const [modalDeleteOpen, setModalDeleteOpen] = useState(false)
  const [modalDeleteChapterOpen, setModalDeleteChapterOpen] = useState(false)
  const [modalDeleteTitleOpen, setModalDeleteTitleOpen] = useState(false)
  const [modalImportOpen, setModalImportOpen] = useState(false)
  const [modalHistoricalOpen, setOpenModalHistorical] = useState(false)

  /* Création des options pour les selects utilisés dans les différentes modales */
  const [titlesOptions, setTitlesOptions] = useState()
  const [tasksOptions, setTasksOptions] = useState()

  /* Recherche sur les tableaux de recherche  */
  const [searchQuery, setSearchQuery] = useState('')
  const [searchInput, setSearchInput] = useState('')

  // const [measures, setMeasures] = useState()
  const [titles, setTitles] = useState()
  const [tasksByCategories, setTasksByCategories] = useState()
  const [tasks, setTasks] = useState()

  const [rows, setRows] = useState()
  const [titlesVisibles, setTitlesVisibles] = useState([])
  const [toggleShow, setShowToggle] = useState([])
  const [categorySel, setCategorySel] = useState()
  const [titleSelected, setTitleSelected] = useState()
  const [taskSelected, setTaskSelected] = useState([])
  const [removeVentilation, setRemoveVentilation] = useState(false)
  const [removeVentilationChapter, setRemoveVentilationChapter] = useState(false)
  const [removeVentilationTitle, setRemoveVentilationTitle] = useState(false)

  useEffect(() => {
    getTasks()
    getTasksByCat()
    getTitles()
  }, [])

  useEffect(() => {
    if (titles) {
      const titlesList = (titles.map((title) => {
        return ({ value: title.id, label: title.title })
      }))
      setTitlesOptions(titlesList)
    }

    if (tasks) {
      const tasksList = (tasks.map((task) => {
        return ({ value: task.id, label: task.label })
      }))
      setTasksOptions(tasksList)
    }
  }, [tasks, titles, measures])

  useEffect(() => {
    if (searchInput === '') getTasksByCat()

    const filteredRows = tasksByCategories?.map((cat) => {
      const newTask = {
        id: cat.id,
        title: cat.title,
        tasks: []
      }
      const data = cat.tasks.data.filter((task) => (
        task.in_progress === 0 ? task : task?.label.toLowerCase().includes(searchInput.toLowerCase()) ? task : ''
      ))
      newTask.tasks.data = data
      return newTask
    })
    setTasksByCategories(filteredRows)
  }, [searchInput])

  useEffect(() => {
    const temp = []

    tasksByCategories?.map((title) => {
      const chapters = []

      title.chapters.data.map((temp) => {
        const tasks = []

        temp.tasks.data.map((task) => (
          tasks.push({
            ...task,
            unit: tasks?.filter((taskParent) => taskParent.label === task.label)[0]?.measure.unit
          })
        ))

        chapters.push({
          ...temp,
          tasks
        })

        return true
      })

      temp.push({
        ...title,
        chapters
      })

      return true
    })

    setRows(Object.values(temp))
  }, [tasksByCategories])

  const getTasksByCat = () => {
    callApi({
      method: 'get',
      url: `admin/construction-sites/${chantier.id}/tasks`
    }).then(res => {
      if (!res) return
      setTasksByCategories(res.data.data)
    })
  }

  const getTitles = () => {
    callApi({
      method: 'get',
      url: `admin/construction-sites/${chantier.id}/titles`
    }).then(res => {
      if (!res) return
      setTitles(res.data.data)
    })
  }

  const getTasks = () => {
    callApi({
      method: 'get',
      url: 'admin/tasks'
    }).then(res => {
      if (!res) return
      setTasks(res.data.data)
    })
  }

  const deleteTask = async () => {
    callApi({
      method: 'delete',
      url: `admin/construction-sites/${chantier.id}/tasks/${taskSelected.id}?removeClockingsEntryTasks=${!removeVentilation ? 0 : 1}`
    })
      .then(res => {
        if (!res) return

        toast({
          position: 'bottom-right',
          description: res?.data?.message || 'Tâche supprimée avec succès',
          status: 'success',
          duration: 5000,
          isClosable: false
        })
        getTasksByCat()
      })
  }

  const editTask = (task) => {
    callApi({
      method: 'put',
      url: `admin/construction-sites/${chantier.id}/tasks/${task.id}`,
      data: {
        in_progress: !task.in_progress
      }
    })
      .then(res => {
        if (!res) return
        toast({
          position: 'bottom-right',
          description: res?.data?.message || 'Tâche modifiée avec succès',
          status: 'success',
          duration: 5000,
          isClosable: false
        })
        getTasksByCat()
      })
  }

  const toggleSubrows = (id) => {
    const copy = [...toggleShow]
    const index = copy.indexOf(id)
    copy.includes(id) ? copy.splice(index, 1) : copy.push(id)
    setShowToggle(copy)
  }

  const deleteTitle = () => {
    callApi({
      method: 'delete',
      url: `admin/construction-sites/${chantier.id}/titles/${titleSelected.id}?removeClockingsEntryTasks=${removeVentilationChapter ? 1 : 0}`
    }).then(res => {
      toast({
        position: 'bottom-right',
        description: res?.data?.message || `${titleSelected.title} supprimée avec succès`,
        status: 'success',
        duration: 5000
      })
      getTasksByCat()
      setModalDeleteTitleOpen(false)
      setModalEditTitleOpen(false)
    })
  }

  const deleteCategory = () => {
    callApi({
      method: 'delete',
      url: `admin/construction-sites/${chantier.id}/chapters/${categorySel.id}?removeClockingsEntryTasks=${removeVentilationChapter ? 1 : 0}`
    }).then(res => {
      toast({
        position: 'bottom-right',
        description: res?.data?.message || `${categorySel.title} supprimée avec succès`,
        status: 'success',
        duration: 5000
      })
      getTasksByCat()
      setModalDeleteChapterOpen(false)
      setModalEditChapterOpen(false)
    })
  }

  const renderRows = (inProgress, searchText) => {
    return rows && rows.map((title) => {
      return (
        <Tbody key={`title-${title.id}`} className="intervention-tbody">
          <InterventionTitleLine
            className="intervention-title"
            hasChild={title.chapters.length > 0}
            onToggle={inProgress
              ? null
              : (e) => {
                  e.stopPropagation()
                  setTitlesVisibles(
                    !titlesVisibles.includes(title.id)
                      ? [...titlesVisibles, title.id]
                      : titlesVisibles.filter(temp => temp !== title.id)
                  )
                }}
            onEdit={(e) => {
              e.stopPropagation()
              setTitleSelected(title)
              setModalEditTitleOpen(true)
            }}
            onAdd={inProgress
              ? null
              : (e) => {
                  e.stopPropagation()
                  setTitleSelected(title)
                  setModalOpenCategory(true)
                }}
            title={title}
          />
          {(inProgress || titlesVisibles.includes(title.id)) && title.chapters.map((chapter) => (
            <>
              <InterventionTitleLine
                className="intervention-chapter"
                hasChild={chapter.tasks.length > 0}
                onToggle={inProgress
                  ? null
                  : (e) => {
                      e.stopPropagation()
                      toggleSubrows(chapter.id)
                    }}
                onEdit={(e) => {
                  e.stopPropagation()
                  setCategorySel({ ...chapter, constructionSiteTitle: title })
                  setModalEditChapterOpen(true)
                }}
                onAdd={inProgress
                  ? null
                  : (e) => {
                      e.stopPropagation()
                      setCategorySel({ ...chapter, constructionSiteTitle: title })
                      setTaskSelected(null)
                      setModalEditOpen(true)
                    }}
                title={chapter}
              />
              {chapter.tasks
                .filter((task) => (task.in_progress === Number(inProgress) || task.in_progress === inProgress) &&
                  task.label?.toLowerCase().includes(searchText.toLowerCase()) &&
                  (inProgress || toggleShow.includes(chapter.id))
                )
                .map((task) => (
                  <InterventionTaskLine
                    key={`task-${task.id}`}
                    task={task}
                    onEdit={(e) => {
                      e.stopPropagation()
                      setCategorySel({ ...chapter, constructionSiteTitle: title })
                      setTaskSelected(task)
                      setModalEditOpen(true)
                    }}
                    onDelete={(e) => {
                      e.stopPropagation()
                      setTaskSelected(task)
                      setModalDeleteOpen(true)
                    }}
                    onActivate={() => editTask(task)}
                    onHistory={(e) => {
                      e.stopPropagation()
                      setTaskSelected(task)
                      setOpenModalHistorical(true)
                    }}
                  />
                ))}
            </>
          ))}
        </Tbody>
      )
    })
  }

  return (
    <TabPanel>
      <HStack mb='2'>
        <Stack mr='2'>
          <Text className="mr-2" fontSize='22px' fontWeight='200'>Tâches</Text>
        </Stack>
        <Button className="ml-2" leftIcon={<CiImport />} data-variant='solid' onClick={() => setModalImportOpen(true)}> Importer </Button>
        <Button style={{ display: 'none' }} leftIcon={<CiExport />} data-variant='outline'> Exporter </Button>
      </HStack>
      <Card px='2' py='6' mr='0'>
        <HStack>
          <Box style={{ width: '50%', alignSelf: 'flex-start' }}>
            <Text fontSize='22px' px='5' mb='2'>Initiales</Text>
            <Card padding='20px'>
              <HStack>
              </HStack>
              <TableContainer>
                <Table>
                  <Thead>
                    <Tr>
                      <Th className='rowSearch' style={{ textTransform: 'none', fontFamily: 'Roboto', letterSpacing: '0.00938em', color: 'var(--chakra-colors-chakra-body-text)' }}
                      >
                      <Input name='Rechercher...' value={searchQuery} setValue={setSearchQuery}/>
                      </Th>
                      <Th> Prévues </Th>
                      <Th> Réalisés </Th>
                      <Th> Statut </Th>
                    </Tr>
                  </Thead>
                  {renderRows(false, searchQuery)}
                </Table>
              </TableContainer>
            </Card>
          </Box>
          <Box style={{ width: '50%', alignSelf: 'flex-start' }} mr='2'>
            <Text fontSize='22px' px='5' mb='2'>En cours</Text>
            <Card padding='20px'>
              <TableContainer>
                <Table>
                  <Thead>
                    <Tr>
                      <Th className='rowSearch' style={{ textTransform: 'none', fontFamily: 'Roboto', letterSpacing: '0.00938em', color: 'var(--chakra-colors-chakra-body-text)', top: '15px' }}>
                        <Input name='Rechercher...' value={searchInput} setValue={setSearchInput}/> </Th>
                      <Th> Prévues </Th>
                      <Th> Réalisés </Th>
                      <Th> Statut </Th>
                    </Tr>
                  </Thead>
                  {renderRows(true, searchInput)}
                </Table>
              </TableContainer>
            </Card>
          </Box>
          </HStack>
          <HStack p='3'>
            <VStack align="flex-start">
              <Button
                leftIcon={<AddIcon fontSize='lg'/>}
                style={{ color: '#ff5464', backgroundColor: 'white', fontWeight: '500' }}
                onClick={() => setModalOpenTitle(true)}
              >
                Créer un titre
              </Button>
              <Button
                leftIcon={<AddIcon fontSize='lg'/>}
                style={{ color: '#ff5464', backgroundColor: 'white', fontWeight: '500' }}
                onClick={() => {
                  setTitleSelected(null)
                  setModalOpenCategory(true)
                }}
              >
                Créer un titre de catégorie
              </Button>
            </VStack>
            <Button data-variant='solid' onClick={() => setModalOpen(true)}>
              Créer une nouvelle tâche
            </Button>
          </HStack>
      </Card>
      {modalOpen &&
        <AddTask
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          reloadData={getTasks}
          measuresList={measures}
        />
      }
      {
        <EditTask
          open={modalEditOpen}
          onClose={() => setModalEditOpen(false)}
          taskSelected={taskSelected}
          categorySelected={categorySel}
          titles={titlesOptions}
          tasksList={tasksOptions}
          chantier={chantier.id}
          reloadData={getTasksByCat}
          tasks={tasks}
          titlesOptions={titlesOptions}
        />
      }
      <EditTitle
        open={modalEditTitleOpen}
        onClose={() => setModalEditTitleOpen(false)}
        onDelete={() => setModalDeleteTitleOpen(true)}
        titleSelected={titleSelected}
        constructionSite={chantier}
        reloadData={getTasksByCat}
      />
      <EditChapter
        open={modalEditChapterOpen}
        onClose={() => setModalEditChapterOpen(false)}
        onDelete={() => setModalDeleteChapterOpen(true)}
        taskSelected={taskSelected}
        categorySelected={categorySel}
        constructionSite={chantier}
        reloadData={getTasksByCat}
      />
      <ConfirmDelete
        open={modalDeleteTitleOpen}
        onClose={() => setModalDeleteTitleOpen(false)}
        confirm={deleteTitle}
        cancel={() => setModalDeleteTitleOpen(false)}
        items={[{ ...titleSelected, label: titleSelected?.title }]}
        label={'Titre'}
        customMessage='⚠️ Attention, en supprimant le titre de ce chantier, cela supprimera les ventilations affectées.'
        customPostMessage={<Box display='flex' flexDirection="row">
          <CheckboxGroup>
            <Checkbox
              onChange={() => {
                setRemoveVentilationTitle(prev => !prev)
              }}
              isChecked={removeVentilationTitle}
            />
            <Text ml={2}>Forcer la suppression des ventilations de ce titre</Text>
          </CheckboxGroup>
        </Box>}
      />
      <ConfirmDelete
        open={modalDeleteChapterOpen}
        onClose={() => setModalDeleteChapterOpen(false)}
        confirm={deleteCategory}
        cancel={() => setModalDeleteChapterOpen(false)}
        items={[{ ...categorySel, label: categorySel?.title }]}
        label={'Catégorie'}
        customMessage='⚠️ Attention, en supprimant la catégorie de ce chantier, cela supprimera les ventilations affectées.'
        customPostMessage={<Box display='flex' flexDirection="row">
          <CheckboxGroup>
            <Checkbox
              onChange={() => {
                setRemoveVentilationChapter(prev => !prev)
              }}
              isChecked={removeVentilationChapter}
            />
            <Text ml={2}>Forcer la suppression des ventilations de cette catégorie</Text>
          </CheckboxGroup>
        </Box>}
      />
      {modalDeleteOpen && (
      <ConfirmDelete
        open={modalDeleteOpen}
        onClose={() => setModalDeleteOpen(false)}
        confirm={deleteTask}
        cancel={() => setModalDeleteOpen(false)}
        items={[taskSelected]}
        label={'Tâche'}
        customMessage='⚠️ Attention, en supprimant la tâche de ce chantier, cela supprimera la ventilation affectée'
        customPostMessage={<Box display='flex' flexDirection="row">
          <CheckboxGroup>
            <Checkbox
              onChange={() => {
                setRemoveVentilation(prev => !prev)
              }}
              isChecked={removeVentilation}
            />
            <Text ml={2}>Forcer la suppression des ventilations associées</Text>
          </CheckboxGroup>
        </Box>}
        />
      )}
      {modalImportOpen &&
        <ImportTask
          constructionSite={chantier}
          open={modalImportOpen}
          onClose={() => setModalImportOpen(false)}
          reloadData={getTasksByCat}
        />
      }
      <AddTitle
        open={modalOpenTitle}
        onClose={() => setModalOpenTitle(false)}
        chantier={chantier}
        callback={getTasksByCat}
      />
      <AddCategory
        open={modalOpenCategory}
        onClose={() => setModalOpenCategory(false)}
        callback={getTasksByCat}
        titleOptions={titlesOptions}
        titleSelected={titleSelected}
      />
      {modalHistoricalOpen &&
      <HistoricalModal
        open={modalHistoricalOpen}
        onClose={() => setOpenModalHistorical(false)}
        chantierId={chantier.id}
        taskSelected={taskSelected}
      />
      }
    </TabPanel>
  )
}

export default Interventions
