import {
  Modal,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Text,
  ModalOverlay,
  useToast,
  Stack,
  Icon
} from '@chakra-ui/react'
import { Input } from 'components'
import { useEffect, useState } from 'react'
import { useAuth } from 'contexts'
import { FiTrash2 } from 'react-icons/fi'

const EditTitle = ({ open, onClose, titleSelected, constructionSite, reloadData, onDelete }) => {
  const { callApi } = useAuth()
  const toast = useToast()
  const [title, setTitle] = useState(titleSelected?.title)

  useEffect(() => {
    setTitle(titleSelected?.title)
  }, [titleSelected])

  const submit = () => {
    callApi({
      method: 'put',
      url: `admin/construction-sites/${constructionSite.id}/titles/${titleSelected.id}`,
      data: {
        title
      }
    }).then(res => {
      toast({
        position: 'bottom-right',
        description: res?.data?.message || `${titleSelected.title} modifié avec succès`,
        status: 'success',
        duration: 5000
      })
      reloadData()
      onClose()
    })
  }

  return (
      <Modal
        blockScrollOnMount={true}
        scrollBehavior="inside"
        size="3xl"
        isOpen={open}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent
          w={{
            base: '95%',
            md: '50%',
            lg: '45%'
          }}
        >
            <ModalHeader>
                <Text>
                    {'Modifier le titre'}
                </Text>
                <ModalCloseButton />
            </ModalHeader>
            <ModalBody>
                <Stack spacing={6} my='5'>
                  <Input name='Titre' value={title} setValue={setTitle} />
                </Stack>
            </ModalBody>
            <ModalFooter zIndex={12} justifyContent="center" borderTopWidth={'1px'}>
            <Button data-variant='solid' mr='8' onClick={submit} disabled={!title?.length}>
              Modifier
            </Button>
            <Button data-variant='outline' ml='8' mr='8' onClick={onClose}>
              Annuler
            </Button>
            <Button data-variant='outline' aria-label="Delete category" onClick={onDelete} ml='8'>
              <Icon as={FiTrash2} fontSize="1.25rem" />
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
  )
}

export default EditTitle
