import { Modal, ModalContent, ModalCloseButton, ModalBody, ModalFooter, ModalHeader, Button, Text, ModalOverlay, useToast } from '@chakra-ui/react'
import { Input } from 'components'
import { useState } from 'react'
import { useAuth } from 'contexts'

const AddTitle = ({ open, onClose, category, chantier, callback }) => {
  const { callApi } = useAuth()
  const toast = useToast()
  const [title, setTitle] = useState(category ? category.title : undefined)

  const submit = () => {
    if (!title) return

    callApi({
      method: 'post',
      url: `admin/construction-sites/${chantier.id}/titles`,
      data: Object.assign({
        title
      }
      )
    }).then(res => {
      if (!res) return
      toast({
        position: 'bottom-right',
        description: res?.data?.message || `${title} ajouté avec succès`
      })
      callback()
      onClose()
    })
  }

  return <Modal
    blockScrollOnMount={true}
    scrollBehavior="inside"
    size="3xl"
    isOpen={open}
    onClose={onClose}>
    <ModalOverlay />
    <ModalContent
      w={{
        base: '95%',
        md: '50%',
        lg: '45%'
      }}
    >
        <ModalHeader>
            <Text>
                Créer un nouveau titre
            </Text>
            <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
            <Text fontSize={14} mb='1.5rem'>
                Pour créer un titre, entrez les informations correspondantes ci-dessous :
            </Text>
            <Input name='Titre' value={title} setValue={setTitle}></Input>
        </ModalBody>
        <ModalFooter zIndex={12} justifyContent="center" borderTopWidth={'1px'}>
        <Button data-variant='solid' mr='8' onClick={submit}>
          Valider
        </Button>
        <Button data-variant='outline' ml='8' onClick={onClose}>
          Annuler
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
}

export default AddTitle
