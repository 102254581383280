import {
  Modal,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Text,
  ModalOverlay,
  useToast,
  Stack
} from '@chakra-ui/react'
import { Input, Select } from 'components'
import { useEffect, useState } from 'react'
import { useAuth } from 'contexts'

const AddCategory = ({ open, onClose, category, callback, titleOptions, titleSelected }) => {
  const { callApi } = useAuth()
  const toast = useToast()
  const [constructionSiteTitle, setConstructionSiteTitle] = useState(undefined)
  const [title, setTitle] = useState()

  useEffect(() => {
    setConstructionSiteTitle(titleSelected ? { value: titleSelected.id, label: titleSelected.title } : undefined)
  }, [titleSelected])

  useEffect(() => {
    setTitle(category ? category.title : undefined)
  }, [category])

  const submit = () => {
    if (!title) return

    callApi({
      method: 'post',
      url: `admin/construction-sites/${constructionSiteTitle.value}/chapters`,
      data: Object.assign({
        title
      }
      )
    }).then(res => {
      if (!res) return
      toast({
        position: 'bottom-right',
        description: res?.data?.message || `${title} ajouté avec succès`
      })
      callback()
      onClose()
    })
  }

  return <Modal
    blockScrollOnMount={true}
    scrollBehavior="inside"
    size="3xl"
    isOpen={open}
    onClose={onClose}>
    <ModalOverlay />
    <ModalContent
      w={{
        base: '95%',
        md: '50%',
        lg: '45%'
      }}
    >
        <ModalHeader>
          <Text>
              Créer un nouveau titre de catégorie
          </Text>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          <Text fontSize={14} mb='1.5rem'>
              Pour créer un titre de catégorie, entrez les informations correspondantes ci-dessous :
          </Text>
          <Stack spacing={6} my='5'>
            <Select name='Dossier parent' isDisabled={Boolean(titleSelected)} value={constructionSiteTitle} setValue={setConstructionSiteTitle} options={titleOptions}></Select>
            <Input name='Titre' value={title} setValue={setTitle}></Input>
          </Stack>
        </ModalBody>
        <ModalFooter zIndex={12} justifyContent="center" borderTopWidth={'1px'}>
        <Button data-variant='solid' mr='8' onClick={submit} disabled={!constructionSiteTitle || !title?.length}>
          Valider
        </Button>
        <Button data-variant='outline' ml='8' onClick={onClose}>
          Annuler
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
}

export default AddCategory
