import { Button, Checkbox, HStack, Icon, Td, Text, Tr } from '@chakra-ui/react'
import { FiEdit2, FiTrash2 } from 'react-icons/fi'
import { WarningTwoIcon } from '@chakra-ui/icons'

const getStatusColors = (status) => {
  let statusColors
  if (typeof status === 'number') {
    statusColors = {
      0: '#FCC35B',
      1: '#1BC181'
    }
  } else {
    statusColors = {
      false: '#FCC35B',
      true: '#1BC181'
    }
  }
  return statusColors[status] ?? '#DE4433'
}

const InterventionTaskLine = ({ task, onActivate, onDelete, onEdit, onHistory }) => {
  return (
    <Tr key={task.id}>
      <Td size='sm' style={{ padding: '1rem', paddingLeft: '80px' }}>
        <HStack>
          <Button size='xs' variant='ghost' aria-label='Edit task' className='icon-btn' onClick={onEdit} >
            <Icon as={FiEdit2} fontSize='1rem'></Icon>
          </Button>
          <Button size='xs' variant="ghost" aria-label="Delete task" className='icon-btn' onClick={onDelete} m='0' p='0'>
            <Icon as={FiTrash2} fontSize="1.25rem" />
          </Button>
          {task.label !== null
            ? <Text style={{ cursor: 'pointer' }} fontSize='xs' onClick={onHistory}>{task.label}</Text>
            : <><Icon as={WarningTwoIcon} fontSize="1rem" />
              <Text fontSize='xs'> La tâche parente à été supprimée
              </Text>
            </>
          }
        </HStack>
      </Td>
      <Td style={{ padding: '0 10px' }}>
        <HStack justifyContent={'center'} style={{ backgroundColor: '#f5f5f5', borderRadius: '0.375rem' }} py='3'>
          <Text fontSize='xs'>{task.quantity_planned} {task.unit || ''} - </Text>
          <Text fontSize='xs'>{task.hours_planned}H</Text>
        </HStack>
      </Td>
      <Td style={{ padding: '0 10px' }}>
        <HStack justifyContent={'center'} style={{ backgroundColor: '#f5f5f5', borderRadius: '0.375rem' }} py='3'>
          <Text fontSize='xs'>{task.quantity_worked} {task.unit || ''}-</Text>
          <Text fontSize='xs'>{Math.floor(task.hours_worked / 60)}h{task.hours_worked % 60}min</Text>
        </HStack>
      </Td>
      <Td>
        <HStack>
          <Text color="muted" className='container-text'>
            <span style={{ minWidth: '10px', minHeight: '10px', background: getStatusColors(task.in_progress), borderRadius: '50%', marginRight: '5px', display: 'inline-block', cursor: 'default' }} />
          </Text>
          {task.in_progress === 0 || !task.in_progress ? <Text fontSize='xs'> Pas commencer </Text> : <Text fontSize='xs'> En cours</Text>}
          <Checkbox onChange={onActivate} />
        </HStack>
      </Td>
    </Tr>
  )
}

export default InterventionTaskLine
