import { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'

import Moment from 'moment'

import { Box, Text, Icon, Button, useToast, CircularProgress } from '@chakra-ui/react'
import { FiEye, FiEdit2, FiTrash2 } from 'react-icons/fi'

import { useAuth, useModals } from 'contexts'

import { Table } from 'components'
import AddChantier from './components/modals/AddChantier'
import { downloadExcelFile, generateExcelFile } from '../../../helpers/excel'
import moment from 'moment/moment'

const getStatusColors = (status) => {
  const statusColors = {
    processing: '#FCC35B',
    terminated: '#1BC181'
  }

  return statusColors[status] ?? '#DE4433'
}

const columns = [
  {
    key: 'id',
    name: 'id',
    display: (value, _data) => value,
    canSort: true,
    displayByDefault: true
  },
  {
    key: 'updated_at',
    name: 'modification',
    display: (_value, { updatedAt }) => updatedAt ? Moment(updatedAt.slice(0, 10) + ' ' + updatedAt.slice(11, 19)).format('DD/MM/YYYY - HH:mm') : '-',
    canSort: true,
    displayByDefault: true
  },
  {
    key: 'name',
    name: 'nom',
    display: (value, _data) => value,
    canSort: true,
    displayByDefault: true
  },
  {
    key: 'cdt',
    name: 'cdt',
    display: (value, _data) => value?.lastName ?? ' - ',
    canSort: true,
    displayByDefault: true
  },
  {
    key: 'cdc',
    name: 'cdc',
    display: (value, _data) => value?.lastName ?? ' - ',
    canSort: true,
    displayByDefault: true
  },
  {
    key: 'address',
    name: 'adresse',
    display: (_value, { fullAddress }) => fullAddress,
    canSort: true,
    displayByDefault: true
  },
  {
    key: 'budget',
    name: 'budget',
    display: (value, _data) => Intl.NumberFormat('fr-FR', {
      style: 'currency',
      currency: 'EUR',
      maximumFractionDigits: 0
    }).format(value),
    canSort: true,
    displayByDefault: true
  },
  {
    key: 'time',
    name: 'heures',
    display: (value) => `${value ? new Intl.NumberFormat('fr-FR').format(value) : '- '}h`,
    canSort: false,
    displayByDefault: true
  },
  {
    key: 'end_date',
    name: 'statut',
    display: (_value, { status }) => <Text color="muted" className='container-text'>
      <span style={{ minWidth: '10px', minHeight: '10px', background: getStatusColors(status.value), borderRadius: '50%', marginRight: '5px', display: 'inline-block', cursor: 'default' }} />
      {status.label}
    </Text>,
    canSort: true,
    displayByDefault: true
  }
]

const Chantiers = () => {
  const { callApi } = useAuth()
  const { openConfirmToDeleteModal } = useModals()
  const toast = useToast()

  const [chantiers, setChantiers] = useState([])
  const [loadingExport, setLoadingExport] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [modalChantier, setModalChantier] = useState()
  const [modalCallback, setModalCallback] = useState()

  const getChantiers = useCallback(({ currentPage, rowsPerPage, sortBy, sortDir, searchInput }, callback) => {
    callApi({
      method: 'get',
      url: `admin/construction-sites?page=${currentPage}&perPage=${rowsPerPage}&sort[${sortBy}]=${sortDir}&search=${encodeURIComponent(searchInput)}`
    })
      .then(res => {
        if (!res) return
        callback(res)
        setChantiers(res?.data?.data)
        setModalChantier(res?.data?.data)
      })
  }, [])

  const addChantier = (callback) => {
    setModalOpen(true)
    setModalCallback(() => callback)
    setModalChantier(null)
  }

  const editChantier = (chantier, callback) => {
    setModalOpen(true)
    setModalChantier(chantier)
    setModalCallback(() => callback)
  }

  const deleteChantier = (chantier, callback) => {
    callApi({
      method: 'delete',
      url: `admin/construction-sites/${chantier.id}`
    })
      .then((res) => {
        toast({
          position: 'bottom-right',
          description: 'Ce chantier a été bien supprimé.',
          status: 'success',
          duration: 5000,
          isClosable: false
        })
        callback(res)
      })
  }

  const deleteChantiers = async (selectedChantiers, callback) => {
    if (!selectedChantiers.length) return
    openConfirmToDeleteModal({
      label: 'Chantiers',
      items: chantiers.filter(i => selectedChantiers.includes(i.id)).map(i => ({ id: i.id, label: i.name })),
      confirm: () => callApi({
        method: 'delete',
        url: 'admin/construction-sites/delete/multiple',
        data: {
          ids: selectedChantiers
        }
      })
        .then(res => {
          if (!res) return
          toast({
            position: 'bottom-right',
            description: res?.data?.message || 'Chantiers supprimés avec succès',
            status: 'success',
            duration: 5000,
            isClosable: false
          })
          callback(res)
        })
    })
  }

  const actions = (chantier, callback) => {
    return <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
      <Link to={`/admin/construction-sites/${chantier.id}`}>
        <Button variant="ghost" aria-label="Show member" className='icon-btn' m='0' p='0'>
          <Icon as={FiEye} fontSize="1.25rem" color="#8186aa" />
        </Button>
      </Link>
      <Button variant="ghost" aria-label="Edit member" className='icon-btn' onClick={() => editChantier(chantier, callback)} m='0' p='0'>
        <Icon as={FiEdit2} fontSize="1.25rem" color="#8186aa" />
      </Button>
      <Button variant="ghost" aria-label="Delete member" className='icon-btn' onClick={() =>
        openConfirmToDeleteModal({
          label: 'Chantiers',
          items: [{ id: chantier.id, label: chantier.name }],
          confirm: () => deleteChantier(chantier, callback)
        })} m='0' p='0' color="#8186aa">
        <Icon as={FiTrash2} fontSize="1.25rem" color="#8186aa" />
      </Button>
    </Box>
  }

  const exportConstructionSites = () => {
    setLoadingExport(true)
    callApi({
      method: 'get',
      url: 'admin/construction-sites?sort[name]=ASC&limit=1000'
    })
      .then(constructionSites => {
        const workbook = generateExcelFile()
        const worksheet = workbook.addWorksheet('Chantiers')

        const worksheetColumns = [
          { header: 'Chantier', key: 'constructionSite', width: 30 },
          { header: 'Adresse', key: 'constructionSite', width: 30 },
          { header: 'Date début', key: 'constructionSite', width: 30 },
          { header: 'Date fin', key: 'constructionSite', width: 30 },
          { header: 'Zone', key: 'constructionSite', width: 30 },
          { header: 'Budget estimé', key: 'constructionSite', width: 30 },
          { header: 'Heures prévues', key: 'constructionSite', width: 30 }
        ]

        worksheet.addRow(worksheetColumns.map((data) => data.header))

        constructionSites
          .data
          .data
          .forEach(data => {
            worksheet.addRow([
              data.name,
              data.address,
              data.startDatedmY,
              data.endDatedmY,
              data.constructionSiteZone?.name ?? '',
              data.budget,
              data.time
            ])
          })
        downloadExcelFile(workbook, `chantiers-en-cours-au-${moment().format('DD-MM-YYYY')}`)
      }).finally(() => setLoadingExport(false))
  }

  return <Box>
    <Table
        getDatas={getChantiers}
        canBeSelected={true}
        canSearch={true}
        actions={actions}
        columns={columns}
        addOnClick={addChantier}
        deleteSelectedRowsOnClick={deleteChantiers}
        defaultSortBy="end_date"
        defaultSortDir='desc'
        extraButtons={(
          <Button data-variant='solid' onClick={exportConstructionSites}>
            {loadingExport ? <CircularProgress isIndeterminate color='#FFF' size='30px' /> : 'Exporter'}
          </Button>
        )}
    />
    <AddChantier open={modalOpen} onClose={() => setModalOpen(false)} chantier={modalChantier} callback={modalCallback} />
  </Box>
}

export default Chantiers
