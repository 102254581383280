import { Button, HStack, Icon, Td, Text, Tr } from '@chakra-ui/react'
import { SmallAddIcon } from '@chakra-ui/icons'
import { FiEdit2 } from 'react-icons/fi'

const InterventionTitleLine = ({ className, hasChild, title, onAdd, onEdit, onToggle }) => {
  return (
    <Tr key={title.id} className={className}>
      <Td>
        <HStack>
          {onToggle
            ? (
            <Icon
              style={{ cursor: 'pointer', opacity: hasChild ? 1 : 0 }}
              as={SmallAddIcon}
              onClick={onToggle}
            />
              )
            : null}
          <Button
            size='xs'
            variant='ghost'
            aria-label='Edit title'
            className='icon-btn'
            onClick={onEdit}
          >
            <Icon as={FiEdit2} fontSize='1rem' />
          </Button>
          <Text fontSize='sm'>{title.title}</Text>
          {onAdd
            ? (
            <Button size='xs'>
              <Icon as={SmallAddIcon} onClick={onAdd} />
            </Button>
              )
            : null}
        </HStack>
      </Td>
    </Tr>
  )
}

export default InterventionTitleLine
