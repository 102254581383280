import { useEffect, useState } from 'react'

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Text,
  Stack,
  Icon,
  Grid,
  GridItem,
  useToast,
  Box,
  Checkbox, CheckboxGroup
} from '@chakra-ui/react'
import { BsCurrencyEuro } from 'react-icons/bs'
import { BiTimeFive } from 'react-icons/bi'

import { PictureInput, Input, Select, AddressInput, ColorInput, DateTimeInput } from 'components'

import { fileToBePost } from 'helpers/utils'

import ReferentSelect from '../ReferentSelect'

import { useAuth, useDatas } from 'contexts'

import moment from 'moment'

const defaultActors = (editActors = []) => {
  // const filter1 = editActors.filter(actor => actor?.constructionActorType?.id === 1)
  // const filter2 = editActors.filter(actor => actor?.constructionActorType?.id === 2)
  const filter3 = editActors.filter(actor => actor?.constructionActorType?.id === 3)
  const filter4 = editActors.filter(actor => actor?.constructionActorType?.id === 4)
  // const filter5 = editActors.filter(actor => actor?.constructionActorType?.id === 5)

  editActors = editActors.filter(actor => ![filter3[0]?.id, filter4[0]?.id].includes(actor.id))// filter1[0]?.id, filter2[0]?.id, filter3[0]?.id, filter4[0]?.id, filter5[0]?.id

  const actors = [
    {
      id: `actor-${Math.random() * 100000}`,
      type: 4,
      referent: filter4[0]?.constructionActorReferent?.id ?? null,
      default: {
        type: 4,
        referent: filter4[0]?.constructionActorReferent?.id ?? null,
        disabledEditType: true
      }
    },
    {
      id: `actor-${Math.random() * 100000}`,
      type: 3,
      referent: filter3[0]?.constructionActorReferent?.id ?? null,
      default: {
        type: 3,
        referent: filter3[0]?.constructionActorReferent?.id ?? null,
        disabledEditType: true
      }
    },
    // {
    //   id: `actor-${Math.random() * 100000}`,
    //   type: 2,
    //   referent: filter2[0]?.constructionActorReferent?.id ?? null,
    //   default: {
    //     type: 2,
    //     referent: filter2[0]?.constructionActorReferent?.id ?? null,
    //     disabledEditType: true
    //   }
    // },
    // {
    //   id: `actor-${Math.random() * 100000}`,
    //   type: 1,
    //   referent: filter1[0]?.constructionActorReferent?.id ?? null,
    //   default: {
    //     type: 1,
    //     referent: filter1[0]?.constructionActorReferent?.id ?? null,
    //     disabledEditType: true
    //   }
    // },
    // {

    //   id: `actor-${Math.random() * 100000}`,
    //   type: 5,
    //   referent: filter5[0]?.constructionActorReferent?.id ?? null,
    //   default: {
    //     type: 5,
    //     referent: filter5[0]?.constructionActorReferent?.id ?? null,
    //     disabledEditType: true
    //   }
    // },
    ...(editActors.length
      ? editActors.map(actor => ({
        id: `actor-${Math.random() * 100000}`,
        type: actor.constructionActorType?.id,
        referent: actor.constructionActorReferent?.id,
        default: {
          type: actor.constructionActorType?.id,
          referent: actor.constructionActorReferent?.id
        }
      }))
      : [])
  ]
  return actors
}

const AddChantier = ({ open, onClose, chantier, callback = () => {}, viewOnly, editColor }) => {
  const { callApi } = useAuth()

  const [picture, setPicture] = useState()
  const [name, setName] = useState()
  const [budget, setBudget] = useState()
  const [estimatedTime, setEstimatedTime] = useState(0)

  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  const [addressValues, setAddressValues] = useState()

  const [dontCalculateZone, setDontCalculateZone] = useState()
  const [zone, setZone] = useState()

  const [actorTypeOptions, setActorTypeOptions] = useState([])
  const [actors, setActors] = useState([])

  const [color, setColor] = useState()
  const { datas: { zones }, reload: { chantiers: reloadConstructionSites } } = useDatas()

  const toast = useToast()

  useEffect(() => {
    setPicture(chantier?.picture?.uri ? { data_url: chantier.picture.uri } : '')
    setName(chantier?.name ?? '')
    setBudget(chantier?.budget ?? '')
    setEstimatedTime(chantier?.time ?? '')
    setStartDate(chantier?.startDate ? moment(chantier?.startDate).format('YYYY-MM-DD') : null)
    setColor(chantier?.color ?? '')
    setEndDate(chantier?.endDate ? moment(chantier?.endDate).format('YYYY-MM-DD') : null)
    setZone(chantier?.constructionSiteZone ? { value: chantier.constructionSiteZone.id, label: chantier.constructionSiteZone.name } : '')
    setDontCalculateZone(chantier?.dontCalculateZone ?? false)
    setActors(defaultActors(chantier?.constructionActors))
  }, [chantier])

  const setActor = ({ actorId, referent, type, toCreate, toDelete }) => {
    if (toCreate) {
      setActors(prevActors => [...prevActors, { id: `actor-${Math.random() * 100000}`, type: null, referent: null }])
    } else if (toDelete) {
      let newActors = [...actors]
      newActors = newActors.filter(actor => actor.id !== actorId)
      setActors(newActors)
    } else {
      setActors(prevActors => prevActors.map(actor => actor.id === actorId
        ? {
            ...actor,
            type,
            referent: actor.type !== type ? null : typeof referent === 'number' ? referent : actor.referent
          }
        : actor))
    }
  }

  const cleanActor = ({ actorId }) => {
    setActors(prevActors => prevActors.map(actor => actor.id === actorId
      ? {
          ...actor,
          referent: null
        }
      : actor))
  }

  const submit = async () => {
    if (chantier &&
      (
        (picture && picture?.data_url !== chantier?.picture?.uri) ||
        (!picture && chantier.picture)
      )
    ) {
      callApi({
        method: 'post',
        url: `admin/construction-sites/${chantier.id}/edit-file`,
        formData: true,
        data: Object.assign(
          picture ? { picture: fileToBePost(picture.data_url) } : {}
        )
      })
    }

    callApi({
      method: chantier ? 'put' : 'post',
      url: chantier ? `admin/construction-sites/${chantier.id}` : 'admin/construction-sites',
      formData: !chantier,
      data: Object.assign(
        {
          name,
          color,
          start_date: startDate ? moment(startDate).format('YYYY-MM-DD') : '',
          end_date: endDate ? moment(endDate).format('YYYY-MM-DD') : '',
          budget: budget ?? 0,
          time: estimatedTime,
          dont_calculate_zone: dontCalculateZone ? 1 : 0,
          ...addressValues
        },
        !dontCalculateZone && zone ? { construction_site_zone_id: zone.value } : {},
        chantier ? { id: chantier.id } : {},
        actors.filter(actor => actor.referent && actor.type).length > 0 || chantier?.constructionActors?.length
          ? {
              construction_actors: actors.filter(actor => actor.referent && actor.type).map(actor => ({
                construction_actor_type_id: actor.type,
                construction_actor_referent_id: actor.referent
              })) || []
            }
          : {},
        picture && !chantier ? { picture: fileToBePost(picture.data_url) } : {}
      )
    })
      .then(res => {
        if (!res) return
        toast({
          position: 'bottom-right',
          description: res?.data?.message || chantier ? 'Chantier modifié avec succès' : 'Chantier ajouté avec succès',
          status: 'success',
          duration: 5000,
          isClosable: false
        })
        reloadConstructionSites()
        callback(res)
        onClose()
      })
  }

  const getActorTypes = () => {
    callApi({
      method: 'get',
      url: 'admin/search/models/construction-actor-types?search='
    })
      .then(res => setActorTypeOptions(res?.data?.data?.map(data => ({ value: data.id, label: data.label })) || []))
  }

  useEffect(() => {
    if (!open) return
    getActorTypes()
  }, [open])

  return <Modal
    blockScrollOnMount={true}
    scrollBehavior="inside"
    size="2xl"
    isOpen={open}
    onClose={onClose}
  >
    <ModalOverlay />
    <ModalContent
      w={{
        base: '95%',
        md: '50%',
        lg: '35%'
      }}
    >
      <ModalHeader>
        <Text fontSize={24}>
          {chantier ? viewOnly ? 'Informations' : 'Modifier ce chantier' : 'Nouveau Chantier'}
        </Text>
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody>
        {!viewOnly && <Text fontSize={14} mb='1.5rem'>
          {chantier ? "Modifiez les informations d'un chantier en éditant le champ ci-dessous :" : 'Pour ajouter un nouveau chantier, entrez les informations associées ci-dessous :'}
        </Text>}
        <PictureInput picture={picture} setValue={(imageList) => setPicture(imageList[0])} onRemove={() => setPicture(undefined)} disabled={viewOnly} />
        <Stack spacing={5} my='5'>
          <Box align='right'>
            <ColorInput value={color} setValue={setColor} />
            {/* <Input name='Couleur' value={color} setValue={setColor} type='color' style={{ padding: '7px !important' }} width='100px' disabled={viewOnly && !editColor} />
            <Box position='absolute' right='30px' zIndex='1000'>
              <SketchPicker
                color={color}
                onChangeComplete={(v) => setColor(v.hex)}
                presetColors={['#20B5A3', '#EF474C', '#FCBB3D', '#5A1951', '#5FB96E', '#34D3CB']}
              />
            </Box> */}
          </Box>
          <Input name='Name *' value={name} setValue={setName} disabled={viewOnly} />
          <Input name='Budget' value={budget} setValue={setBudget} type='number' icon={<Icon as={BsCurrencyEuro} fontSize="1.3rem" />} disabled={viewOnly} />
          <Input name='Heures prévues' value={estimatedTime} setValue={setEstimatedTime} type='number' icon={<Icon as={BiTimeFive} fontSize="1.3rem" />} disabled={viewOnly} />
          <AddressInput name='Adresse du chantier *' defaultValue={chantier?.address} setValues={setAddressValues} disabled={viewOnly} />
          <Grid templateColumns='repeat(2, 1fr)' gap={6}>
            <GridItem w='100%'>
              <DateTimeInput name='Date début *' value={startDate} setValue={setStartDate} disabled={viewOnly} />
            </GridItem>
            <GridItem w='100%'>
              <DateTimeInput name='Date fin *' value={endDate} setValue={setEndDate} disabled={viewOnly} />
            </GridItem>
          </Grid>
          <Stack>
            <Select value={zone} options={zones} setValue={setZone} placeholder='Zone de chantier *' isDisabled={viewOnly || dontCalculateZone} />
            <Box display='flex' flexDirection="row">
              <CheckboxGroup>
                <Checkbox
                  onChange={() => {
                    if (!dontCalculateZone) {
                      setZone(null)
                    }
                    setDontCalculateZone(prev => !prev)
                  }}
                  isChecked={dontCalculateZone}
                >
                  Ne pas calculer les zones de ce chantier
                </Checkbox>
              </CheckboxGroup>
            </Box>
          </Stack>
          {actorTypeOptions.length && actors.length ? actors.map(actor => <ReferentSelect key={actor.id} cleanActor={() => cleanActor({ actorId: actor.id })} actorTypeOptions={actorTypeOptions} defaultActor={actor.default} setActor={(type, referent) => setActor({ actorId: actor.id, type, referent })} onDelete={() => setActor({ toDelete: true, actorId: actor.id })} viewOnly={viewOnly} />) : ''}
          {!viewOnly && <Button data-variant='outline-white' onClick={() => setActor({ toCreate: true })}>Autre catégorie</Button>}
        </Stack>
      </ModalBody>
      <ModalFooter zIndex={12} justifyContent="center" borderTopWidth={'1px'}>
        <Button data-variant='solid' onClick={viewOnly && !editColor ? onClose : submit}>
          {chantier ? viewOnly && !editColor ? 'Fermer' : 'Mettre à jour' : 'Ajouter ce chantier'}
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
}

export default AddChantier
